import React from "react";

const Header = () => {
    return (
        <div className="my-header">
            <h3>Todo App</h3>
        </div>
    )
}

export default Header;